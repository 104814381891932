<template>
  <div class="ClientProfileUndewriting column">
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Underwriting</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
      >
        Edit
      </button>
    </div>

    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!editing"
        class="column"
        key="notEditing"
      >
        <!-- BASE -->
        <div
          class="column"
          key="base"
        >
          <div class="row row--wrap row--width-auto">
            <div class="column column--width-auto">
              <label class="fc-light fs-12 fw-med mb-7">OFAC VERIFIED</label>
              <label class="fs-14 capitalize">{{ client.ofac_verified }}</label>
            </div>
            <div class="column column--width-auto">
              <label class="fc-light fs-12 fw-med mb-7">TAX GUARD ALERTS</label>
              <label class="fs-14 capitalize">{{ client.tax_guard_alerts }}</label>
            </div>
            <div class="column column--width-auto">
              <label class="fc-light fs-12 fw-med mb-7">CARRIER 411 ALERTS</label>
              <label class="fs-14 capitalize">{{ client.carrier_411_alerts }}</label>
            </div>
          </div>
        </div>

        <!-- UPDATES -->
        <div
          class="column"
          key="update"
        >
          <div class="row row--width-auto">
            <invoice-updates
              class="mt-30"
              :default-entries="2"
              :label="'Updates'"
              :updates="client.client_underwriting_updates"
            />
          </div>
        </div>

        <!-- ADD UPDATE -->
        <transition
          mode="out-in"
          name="Transition__opacity-fade"
        >
          <base-button
            v-if="!showUpdate"
            @click="showAddUpdate"
            class="bg-blue fc-white mt-13"
          >
            Add Update
          </base-button>
          <!-- ADD UPDATE -->
          <div
            v-else
            class="row row--align-center"
            key="updateUpdate"
          >
            <i
              @click="cancelUpdate"
              @keyup.enter="cancelUpdate"
              class="fa fa-add-circle fa-rotate-45 fa-20 fa-3 mr-9 clickable"
              tabindex="0"
            />
            <div class="row row--align-center row--wrap">
              <label class="fs-14 mr-9">Add underwriting update</label>
              <v-select
                v-model="subject"
                aria-label="Updates Subect Select Input"
                :clearable="false"
                :options="subjectOptions"
                :placeholder="'Subject'"
              />
              <base-input
                v-model.trim="update"
                :placeholder="'Underwriting update...'"
              />
              <base-button
                @click="addUnderwritingUpdate"
                :disabled="!update.length || buttonDisabled"
                class="bg-blue fc-white ml-6"
              >
                Save
              </base-button>
            </div>
          </div>
        </transition>
      </div>

      <div
        v-else
        class="column"
        key="editing"
      >
        <!-- EDIT -->
        <div
          v-if="editing"
          key="edit"
        >
          <transition
            mode="out-in"
            name="Transition__opacity-fade"
          >
            <!-- UNDERWRITING CHECKBOXES -->
            <div
              v-if="!showUpdate"
              class="column"
              key="checkboxUpdates"
            >
              <div class="row row--wrap">
                <base-checkbox
                  v-model="duplicateUser.ofacVerified.value"
                  class="mr-16 mb-5"
                  :label-for="'o-f-a-c-verified'"
                  :starting-value="duplicateUser.ofacVerified.value"
                >
                  OFAC Verified
                </base-checkbox>
                <base-checkbox
                  v-model="duplicateUser.taxGuardAlerts.value"
                  class="mr-16 mb-5"
                  :label-for="'tax-guard-alerts'"
                  :starting-value="duplicateUser.taxGuardAlerts.value"
                >
                  Tax Guard Alerts
                </base-checkbox>
                <base-checkbox
                  v-model="duplicateUser.carrier411Alerts.value"
                  class="mr-16 mb-5"
                  :label-for="'carrier-411-alerts'"
                  :starting-value="duplicateUser.carrier411Alerts.value"
                >
                  Carrier 411 Alerts
                </base-checkbox>
              </div>

              <div class="row">
                <base-button
                  @click="saveClientDetails"
                  @keydown.enter="saveClientDetails"
                  class="bg-blue fc-white mr-6"
                >
                  Save
                </base-button>
                <base-button
                  @click="cancelEdit"
                  @keydown.enter="cancelEdit"
                  class="bg-light fc-white"
                >
                  Cancel
                </base-button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
// Helpers
import {
  Client,
  ClientUnderwritingUpdate
} from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseCheckbox from '../../../components/base-checkbox.vue'
import BaseInput from '../../../components/base-input.vue'
import InvoiceUpdates from '../../../components/invoice-updates.vue'

export default {
  name: 'ClientProfileUndewriting',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseInput,
    InvoiceUpdates,
  },

  data () {
    return {
      buttonDisabled: false,
      duplicateUser: null,
      editing: false,
      showUpdate: false,
      subject: 'Corporation Documents',
      subjectOptions: ['Corporation Documents', 'UCC', '8821', 'License', 'Other'],
      update: '',
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    async addUnderwritingUpdate () {
      this.buttonDisabled = true

      this.progressStart()
      try {
        const update = (await ClientUnderwritingUpdate.create({
          client_id: this.client.id,
          note: this.update,
          subject: this.subject,
        })).data
        this.$store.commit('UPDATE_STORED_CLIENT', {
          client_underwriting_updates: [...this.client.client_underwriting_updates, update],
        })
        this.progressFinish()
        this.cancelUpdate()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Underwriting "Add Update"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue adding an underwriting update' })
      }

      this.buttonDisabled = false
    },

    cancelEdit () {
      this.editing = false
      this.duplicateUser = null
    },

    cancelUpdate () {
      this.showUpdate = false
      this.update = ''
    },

    nowEditing () {
      this.showUpdate = false
      this.update = ''
      this.duplicateUser = {
        ofacVerified: {
          value: this.client.ofac_verified,
        },
        carrier411Alerts: {
          value: this.client.carrier_411_alerts,
        },
        taxGuardAlerts: {
          value: this.client.tax_guard_alerts,
        },
      }
      this.editing = true
    },

    async saveClientDetails () {
      this.progressStart()

      try {
        const updatedClient = (await Client.update({
          carrier_411_alerts: this.duplicateUser.carrier411Alerts.value,
          ofac_verified: this.duplicateUser.ofacVerified.value,
          tax_guard_alerts: this.duplicateUser.taxGuardAlerts.value,
          id: this.client.id,
        })).data
        this.$store.commit('UPDATE_STORED_CLIENT', updatedClient)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Underwriting "Save Details"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the client' })
      }

      this.editing = false
    },

    showAddUpdate () {
      this.duplicateUser = null
      this.editing = false
      this.showUpdate = true
      this.update = ''
    },
  },
}
</script>

<style lang="sass">
.ClientProfileUndewriting

  .column--width-auto
    margin-right: rem(37px)

    &:last-child
      margin-right: 0

  .InvoiceUpdates
    padding-left: 0

  .v-select
    margin-right: rem(6px)
    width: rem(235px)
</style>