<template>
  <div class="ClientProfilePaymentPlan column">
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Payment Plan</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
      >
        Edit
      </button>
    </div>

    <!-- BASE -->
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!editing"
        key="base"
      >
        <div class="row row--wrap">
          <div class="column column--width-auto">
            <label class="fs-14 capitalize">{{ client.payment_plan_details }}</label>
          </div>
        </div>

        <div
          v-if="paymentPlanHistory.length"
          class="column column--width-auto"
          @click="toggleHistory"
        >
          <label class="fs-14 capitalize mt-12 fc-light clickable">
            {{ showPaymentPlanHistory
              ? 'Hide History'
              : 'View History'
            }}
          </label>
        </div>

        <!-- history -->
        <invoice-updates
          v-if="showPaymentPlanHistory"
          :default-entries="3"
          :label="'Payment Plan History'"
          :updates="paymentPlanHistory"
        />
      </div>


      <!-- EDIT -->
      <div
        v-else
        class="column"
        key="edit"
      >
        <base-textarea
          v-model.trim="duplicateUser.paymentPlan.value"
          class="ClientProfilePaymentPlan__textarea"
          :label="true"
          :label-for="'payment-plan'"
          :value="client.payment_plan_details"
        />
        <div class="row row--align-center mt-10">
          <base-button
            @click="saveUserDetails"
            @keydown.enter="saveUserDetails"
            class="bg-blue fc-white mr-6"
          >
            Save
          </base-button>
          <base-button
            @click="cancel"
            @keydown.enter="cancel"
            class="bg-light fc-white"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import { Client } from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseTextarea from '../../../components/base-textarea.vue'
import InvoiceUpdates from '../../../components/invoice-updates.vue'

export default {
  name: 'ClientProfilePaymentPlan',

  components: {
    BaseButton,
    BaseTextarea,
    InvoiceUpdates
  },

  data () {
    return {
      duplicateUser: null,
      editing: false,
      paymentPlanHistory: [],
      showPaymentPlanHistory: false
    }
  },

  created () {
    this.getPaymentPlanHistory()
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.duplicateUser = null
    },

    async getPaymentPlanHistory () {
      this.progressStart()

      try {
        const clientHistory = (await Client.history({
          id: this.client.id,
          sort_by: 'updated_at',
          sort_direction: 'DESC'
        })).data.rows

        const paymentPlanHistory = [];
        let previousPlan = null
        clientHistory.forEach(historyEntry => {
          if (historyEntry.payment_plan_details !== previousPlan) {
            previousPlan = historyEntry.payment_plan_details
            paymentPlanHistory.push({
              created_at: historyEntry.created_at,
              update_status: historyEntry.payment_plan_details || 'Payment plan cleared'
            })
          }
        })

        this.paymentPlanHistory = paymentPlanHistory
        this.progressFinish()
      } catch (error) {
        this.captureSentryEvent(
          'Client Payment Plan History "Get History"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the client' })
      }
    },

    nowEditing () {
      this.duplicateUser = {
        paymentPlan: {
          valid: true,
          value: this.client.payment_plan_details,
        },
      }
      this.editing = true
      this.showPaymentPlanHistory = false
    },

    async saveUserDetails () {
      this.progressStart()

      try {
        const updatedClient = (await Client.update({
          payment_plan_details: this.duplicateUser.paymentPlan.value,
          id: this.client.id,
        })).data
        this.$store.commit('UPDATE_STORED_CLIENT', updatedClient)

        this.getPaymentPlanHistory()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Payment Plan "Save Details"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the client' })
      }

      this.editing = false
      this.progressFinish()
    },

    toggleHistory () {
      this.showPaymentPlanHistory = !this.showPaymentPlanHistory
      // reload on expand
      if (this.showPaymentPlanHistory) this.getPaymentPlanHistory()
    }
  }
}
</script>

<style lang="sass">
.ClientProfilePaymentPlan

  .BaseTextarea
    width: 50%

    &__input
      resize: vertical

</style>