var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientProfileAccount column" },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [_vm._v("Account")]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            attrs: { "data-cy": "client-profile-account-edit-btn" },
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c("div", { key: "base", staticClass: "row row--wrap" }, [
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("RATE")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "fs-14 capitalize",
                      attrs: { id: "Testing__ClientFactorRate" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            Number(_vm.client.factor_rate_percentage).toFixed(2)
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("ACCOUNT MANAGER")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.client.account_manager.first_name) +
                        " " +
                        _vm._s(_vm.client.account_manager.last_name) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("SALES REP")
                  ]),
                  _vm.client.sales_rep
                    ? _c("label", { staticClass: "fs-14 capitalize" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.client.sales_rep.first_name) +
                            " " +
                            _vm._s(_vm.client.sales_rep.last_name) +
                            "\n        "
                        )
                      ])
                    : _c("label", { staticClass: "fs-14 capitalize" }, [
                        _vm._v("\n          N/A\n        ")
                      ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("VERIFICATIONS")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "fs-14 capitalize",
                      attrs: {
                        "data-cy": "client-profile-account-verification-text"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.client.requires_verification) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("PREFERRED LANGUAGE")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.client.languages[0]))
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("SALES ATTRIBUTION")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.client.lead_attribution))
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("DATE REGISTERED")
                  ]),
                  _c(
                    "time",
                    {
                      staticClass: "fs-14 capitalize",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          _vm.client.created_at,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(
                              _vm.client.created_at,
                              "YYYY-MM-DD"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ])
              ])
            : _c(
                "div",
                {
                  key: "edit",
                  staticClass: "column",
                  attrs: { "data-cy": "client-profile-account-edit-view" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row row--wrap" },
                    [
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-5",
                          attrs: {
                            label: true,
                            "label-for": "factor-rate",
                            instructions:
                              _vm.instructions
                                .FACTOR_RATE_PERCENTAGE_INSTRUCTION,
                            valid: _vm.duplicateUser.factorRate.valid
                          },
                          model: {
                            value: _vm.duplicateUser.factorRate.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.duplicateUser.factorRate,
                                "value",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "duplicateUser.factorRate.value"
                          }
                        },
                        [_vm._v("\n          Rate\n        ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ClientProfileAccount__select column mb-5 mr-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "fc-light fs-12 mb-7",
                              attrs: { for: "account-manager" }
                            },
                            [
                              _vm._v(
                                "\n            Account Manager\n          "
                              )
                            ]
                          ),
                          _c("v-select", {
                            staticClass: "ClientProfileAccount__select",
                            attrs: {
                              id: "account-manager",
                              clearable: false,
                              "get-option-label": function(employee) {
                                return (
                                  employee.first_name + " " + employee.last_name
                                )
                              },
                              options: _vm.accountManagers
                            },
                            on: {
                              input: function($event) {
                                _vm.duplicateUser.accountManager.value = $event
                              }
                            },
                            model: {
                              value: _vm.duplicateUser.accountManager.value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.duplicateUser.accountManager,
                                  "value",
                                  $$v
                                )
                              },
                              expression: "duplicateUser.accountManager.value"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ClientProfileAccount__select column mb-5 mr-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "fc-light fs-12 mb-7",
                              attrs: { for: "sales-rep" }
                            },
                            [_vm._v("\n            Sales Rep\n          ")]
                          ),
                          _c("v-select", {
                            staticClass: "ClientProfileAccount__select",
                            attrs: {
                              id: "sales-rep",
                              "get-option-label": function(employee) {
                                return (
                                  employee.first_name + " " + employee.last_name
                                )
                              },
                              options: _vm.salespersons
                            },
                            on: {
                              input: function($event) {
                                _vm.duplicateUser.salesRep.value = $event
                              }
                            },
                            model: {
                              value: _vm.duplicateUser.salesRep.value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.duplicateUser.salesRep,
                                  "value",
                                  $$v
                                )
                              },
                              expression: "duplicateUser.salesRep.value"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ClientProfileAccount__select column mb-5 mr-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "fc-light fs-12 mb-7",
                              attrs: { for: "preferred-language" }
                            },
                            [
                              _vm._v(
                                "\n            Preferred Language\n          "
                              )
                            ]
                          ),
                          _c("v-select", {
                            staticClass: "ClientProfileAccount__select",
                            attrs: {
                              clearable: false,
                              id: "preferred-language",
                              options: _vm.languageOptions
                            },
                            on: {
                              input: function($event) {
                                _vm.duplicateUser.preferredLanguage.value = $event
                              }
                            },
                            model: {
                              value: _vm.duplicateUser.preferredLanguage.value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.duplicateUser.preferredLanguage,
                                  "value",
                                  $$v
                                )
                              },
                              expression:
                                "duplicateUser.preferredLanguage.value"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ClientProfileAccount__select column mb-5 mr-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "fc-light fs-12 mb-7",
                              attrs: { for: "sales-attribution" }
                            },
                            [
                              _vm._v(
                                "\n            Sales Attribution\n          "
                              )
                            ]
                          ),
                          _c("v-select", {
                            staticClass: "ClientProfileAccount__select",
                            attrs: {
                              clearable: false,
                              id: "sales-attribution",
                              options: _vm.leadOptions
                            },
                            on: {
                              input: function($event) {
                                _vm.duplicateUser.leadAttribution.value = $event
                              }
                            },
                            model: {
                              value: _vm.duplicateUser.leadAttribution.value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.duplicateUser.leadAttribution,
                                  "value",
                                  $$v
                                )
                              },
                              expression: "duplicateUser.leadAttribution.value"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "base-checkbox",
                        {
                          staticClass: "mr-6 mb-5",
                          attrs: {
                            "data-cy":
                              "client-profile-account-verification-input",
                            "label-for": "verifications",
                            "starting-value":
                              _vm.duplicateUser.verifications.value
                          },
                          model: {
                            value: _vm.duplicateUser.verifications.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.duplicateUser.verifications,
                                "value",
                                $$v
                              )
                            },
                            expression: "duplicateUser.verifications.value"
                          }
                        },
                        [_vm._v("\n          Verifications\n        ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row mt-6" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white mr-6",
                          attrs: {
                            "data-cy": "client-profile-account-save-btn"
                          },
                          on: {
                            click: _vm.saveUserDetails,
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.saveUserDetails($event)
                            }
                          }
                        },
                        [_vm._v("\n          Save\n        ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white",
                          attrs: {
                            "data-cy": "client-profile-account-cancel-btn"
                          },
                          on: {
                            click: _vm.cancel,
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.cancel($event)
                            }
                          }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      )
                    ],
                    1
                  )
                ]
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }