var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientProfileFleet column" },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [_vm._v("Fleet")]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c("div", { key: "base", staticClass: "row row--wrap" }, [
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("COLD")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.client.refrigerated_trucks_amount))
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("DRYVAN")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.client.dryvan_trucks_amount))
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("FLATBED")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.client.flatbed_trucks_amount))
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("OTHER")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.client.other_trucks_amount))
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("STEPDECK")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.client.stepdeck_trucks_amount))
                  ])
                ])
              ])
            : _c("div", { key: "edit", staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "row row--wrap" },
                  [
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "cold-trucks",
                          instructions:
                            _vm.instructions.COLD_TRUCKS_INSTRUCTION,
                          valid: _vm.duplicateUser.cold.valid
                        },
                        model: {
                          value: _vm.duplicateUser.cold.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.cold,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.cold.value"
                        }
                      },
                      [_vm._v("\n          Cold Trucks\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "dryvan-trucks",
                          instructions:
                            _vm.instructions.DRYVAN_TRUCKS_INSTRUCTION,
                          valid: _vm.duplicateUser.dryvan.valid
                        },
                        model: {
                          value: _vm.duplicateUser.dryvan.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.dryvan,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.dryvan.value"
                        }
                      },
                      [_vm._v("\n          Dryvan Trucks\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "flatbed-trucks",
                          instructions:
                            _vm.instructions.FLATBED_TRUCKS_INSTRUCTION,
                          valid: _vm.duplicateUser.flatbed.valid
                        },
                        model: {
                          value: _vm.duplicateUser.flatbed.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.flatbed,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.flatbed.value"
                        }
                      },
                      [_vm._v("\n          Flatbed Trucks\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "other-trucks",
                          instructions:
                            _vm.instructions.OTHER_TRUCKS_INSTRUCTION,
                          valid: _vm.duplicateUser.other.valid
                        },
                        model: {
                          value: _vm.duplicateUser.other.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.other,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.other.value"
                        }
                      },
                      [_vm._v("\n          Other Trucks\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "stepdeck-trucks",
                          instructions:
                            _vm.instructions.STEPDECK_TRUCKS_INSTRUCTION,
                          valid: _vm.duplicateUser.stepdeck.valid
                        },
                        model: {
                          value: _vm.duplicateUser.stepdeck.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.stepdeck,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.stepdeck.value"
                        }
                      },
                      [_vm._v("\n          Stepdeck trucks\n        ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row mt-6" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mr-6",
                        on: {
                          click: _vm.saveUserDetails,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.saveUserDetails($event)
                          }
                        }
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-light fc-white",
                        on: {
                          click: _vm.cancel,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.cancel($event)
                          }
                        }
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    )
                  ],
                  1
                )
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }