<template>
  <div class="ClientProfile">
    <user-profile-authority :page-p-o-v="'client'" />

    <hr class="Divider">

    <user-profile-company :page-p-o-v="'client'" />

    <hr class="Divider">

    <user-profile-insurance :page-p-o-v="'client'" />

    <hr class="Divider">

    <client-profile-account />

    <hr class="Divider">

    <client-profile-underwriting />

    <hr class="Divider">

    <client-profile-fleet />

    <hr class="Divider">

    <client-profile-payment-plan />
  </div>
</template>

<script>
import ClientProfileAccount from './client-profile-account.vue'
import ClientProfileFleet from './client-profile-fleet.vue'
import ClientProfilePaymentPlan from './client-profile-payment-plan.vue'
import ClientProfileUnderwriting from './client-profile-underwriting.vue'
import UserProfileAuthority from '../../../components/user-profile-authority.vue'
import UserProfileCompany from '../../../components/user-profile-company.vue'
import UserProfileInsurance from '../../../components/user-profile-insurance.vue'

export default {
  name: 'ClientProfile',

  components: {
    ClientProfileAccount,
    ClientProfileFleet,
    ClientProfilePaymentPlan,
    ClientProfileUnderwriting,
    UserProfileAuthority,
    UserProfileCompany,
    UserProfileInsurance,
  },
}
</script>

<style lang="sass">
.ClientProfile
  margin-top: rem(42px)
  padding: 0 rem(29px) 2.5rem

  & > div,
  .Divider
    margin-bottom: rem(42px)

    &:last-child
      margin-bottom: 0
</style>