<template>
  <div class="UserProfileInsurance column">
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Insurance</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
      >
        Edit
      </button>
    </div>

    <!-- BASE -->
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!editing"
        class="row row--wrap"
        key="base"
      >
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">AGENCY</label>
          <label class="fs-14 capitalize">
            {{ user.insurance_agency }}
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">COMPANY</label>
          <label class="fs-14 capitalize">
            {{ user.insurance_company }}
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">MONTHLY INSURANCE PAYMENT PER TRUCK</label>
          <label class="fs-14 capitalize">
            {{
              user.insurance_monthly_payment_per_truck
                ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(user.insurance_monthly_payment_per_truck / 100)
                : null
            }}
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">RENEWAL DATE</label>
          <label class="fs-14 capitalize">{{ renewalDate }}</label>
        </div>
      </div>


      <!-- EDIT -->
      <div
        v-else
        class="column"
        key="edit"
      >
        <div class="row row--wrap">
          <base-input
            v-model.trim="duplicateUser.agency.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'insurance-agency'"
            :instructions="instructions.INSURANCE_COMPANY_NAME_INSTRUCTION"
            :valid="duplicateUser.agency.valid"
          >
            Insurance Agency
          </base-input>
          <base-input
            v-model.trim="duplicateUser.company.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'insurance-company'"
            :instructions="instructions.INSURANCE_COMPANY_NAME_INSTRUCTION"
            :valid="duplicateUser.company.valid"
          >
            Insurance Company
          </base-input>
          <base-input
            v-model.trim="duplicateUser.monthlyPayment.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'monthyl-payment-per-truck'"
            :instructions="instructions.MONTHLY_PAYMENT_PER_TRUCK_INSTRUCTION"
            :valid="duplicateUser.monthlyPayment.valid"
          >
            Monthly Payment per Truck
          </base-input>
          <div class="column column--width-auto">
            <label
              class="fc-light fs-12 uppercase mb-7"
              for="renewal-date"
            >
              {{
                duplicateUser.renewalDate.valid
                  ? 'Renewal Date'
                  : instructions.RENEWAL_DATE_INSTRUCTION
              }}
            </label>
            <base-date-picker
              v-model="duplicateUser.renewalDate.value"
              class="mr-6 mb-5"
              id="renewal-date"
              :valid="duplicateUser.renewalDate.valid"
            />
          </div>
        </div>

        <!-- EDIT BUTTONS -->
        <div class="row mt-6">
          <base-button
            @click="saveUserDetails"
            @keydown.enter="saveUserDetails"
            class="bg-blue fc-white mr-6"
          >
            Save
          </base-button>
          <base-button
            @click="cancel"
            @keydown.enter="cancel"
            class="bg-light fc-white"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
import validator from 'validator'
// Helpers
import {
  Client
} from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseDatePicker from './base-date-picker.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'UserProfileInsurance',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    pagePOV: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      corpTypeOptions: [
        'llc',
        'sole proprietor',
        'corp',
      ],
      editing: false,
      duplicateUser: null,
    }
  },

  computed: {
    renewalDate () {
      // Client-only property
      let displayedDate = null
      // Migrated users may not have an insurance renewal date
      if (!this.user.insurance_renewal_date) displayedDate = null
      else displayedDate = moment(this.user.insurance_renewal_date).format('M-D-YYYY')
      return displayedDate
    },

    user () {
      return this.$store.getters.client
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.duplicateUser = null
    },

    nowEditing () {
      this.duplicateUser = {
        agency: {
          valid: true,
          value: this.user.insurance_agency || '',
        },
        company: {
          valid: true,
          value: this.user.insurance_company || '',
        },
        monthlyPayment: {
          valid: true,
          value: this.user.insurance_monthly_payment_per_truck || '',
        },
        renewalDate: {
          valid: true,
          // new Date() alone converts a date to UTC, which could cause the wrong date passing
          // Migrated users may not have an insurance renewal date
          value:
            this.user.insurance_renewal_date
              ? new Date(moment(this.user.insurance_renewal_date))
              : null,
        },
      }

      this.editing = true
    },

    async saveUserDetails () {
      if (!await this.validation()) return

      this.progressStart()

      try {
        const updatedClient = (await Client.update({
          id: this.user.id,
          insurance_agency: this.duplicateUser.agency.value,
          insurance_company: this.duplicateUser.company.value,
          insurance_monthly_payment_per_truck:
            (validator.toFloat(this.duplicateUser.monthlyPayment.value) * 100),
          insurance_renewal_date: this.duplicateUser.renewalDate.value,
        })).data
        this.$store.commit('UPDATE_STORED_CLIENT', updatedClient)
      }
      catch (error) {
        this.captureSentryEvent(
          'User Insurance "Client Update" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the client' })
      }

      this.editing = false
      this.progressFinish()
    },

    async validation () {
      let valid = true

      // agency
      if (
        this.duplicateUser.agency.value
        && !this.isValidAgencyName(this.duplicateUser.agency.value)
      ) {
        this.$set(this.duplicateUser.agency, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.agency, 'valid', true)
      }

      // company
      if (
        this.duplicateUser.company.value
        && !this.isValidInsuranceCompanyName(this.duplicateUser.company.value)
      ) {
        this.$set(this.duplicateUser.company, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.company, 'valid', true)
      }

      // renewal date
      if (
        this.duplicateUser.renewalDate.value
        && !this.isValidRenewalDate(this.duplicateUser.renewalDate.value)
      ) {
        this.$set(this.duplicateUser.renewalDate, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.renewalDate, 'valid', true)
      }

      // monthly payment per truck
      if (
        this.duplicateUser.monthlyPayment.value
        && !this.isValidMonthlyPerTruck(this.duplicateUser.monthlyPayment.value)
      ) {
        this.$set(this.duplicateUser.monthlyPayment, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.monthlyPayment, 'valid', true)
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
.UserProfileInsurance

  .BaseInput
    width: rem(210px)

  .column--width-auto
    margin-right: rem(37px)

    &:last-child
      margin-right: 0
</style>