var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserProfileInsurance column" },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [
          _vm._v("Insurance")
        ]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c("div", { key: "base", staticClass: "row row--wrap" }, [
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("AGENCY")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.user.insurance_agency) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("COMPANY")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.user.insurance_company) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("MONTHLY INSURANCE PAYMENT PER TRUCK")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.user.insurance_monthly_payment_per_truck
                            ? Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(
                                _vm.user.insurance_monthly_payment_per_truck /
                                  100
                              )
                            : null
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("RENEWAL DATE")
                  ]),
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(_vm._s(_vm.renewalDate))
                  ])
                ])
              ])
            : _c("div", { key: "edit", staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "row row--wrap" },
                  [
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "insurance-agency",
                          instructions:
                            _vm.instructions.INSURANCE_COMPANY_NAME_INSTRUCTION,
                          valid: _vm.duplicateUser.agency.valid
                        },
                        model: {
                          value: _vm.duplicateUser.agency.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.agency,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.agency.value"
                        }
                      },
                      [_vm._v("\n          Insurance Agency\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "insurance-company",
                          instructions:
                            _vm.instructions.INSURANCE_COMPANY_NAME_INSTRUCTION,
                          valid: _vm.duplicateUser.company.valid
                        },
                        model: {
                          value: _vm.duplicateUser.company.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.company,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.company.value"
                        }
                      },
                      [_vm._v("\n          Insurance Company\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-6 mb-5",
                        attrs: {
                          label: true,
                          "label-for": "monthyl-payment-per-truck",
                          instructions:
                            _vm.instructions
                              .MONTHLY_PAYMENT_PER_TRUCK_INSTRUCTION,
                          valid: _vm.duplicateUser.monthlyPayment.valid
                        },
                        model: {
                          value: _vm.duplicateUser.monthlyPayment.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.monthlyPayment,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.monthlyPayment.value"
                        }
                      },
                      [
                        _vm._v(
                          "\n          Monthly Payment per Truck\n        "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "column column--width-auto" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "fc-light fs-12 uppercase mb-7",
                            attrs: { for: "renewal-date" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.duplicateUser.renewalDate.valid
                                    ? "Renewal Date"
                                    : _vm.instructions.RENEWAL_DATE_INSTRUCTION
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("base-date-picker", {
                          staticClass: "mr-6 mb-5",
                          attrs: {
                            id: "renewal-date",
                            valid: _vm.duplicateUser.renewalDate.valid
                          },
                          model: {
                            value: _vm.duplicateUser.renewalDate.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.duplicateUser.renewalDate,
                                "value",
                                $$v
                              )
                            },
                            expression: "duplicateUser.renewalDate.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row mt-6" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mr-6",
                        on: {
                          click: _vm.saveUserDetails,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.saveUserDetails($event)
                          }
                        }
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-light fc-white",
                        on: {
                          click: _vm.cancel,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.cancel($event)
                          }
                        }
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    )
                  ],
                  1
                )
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }