var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientProfile" },
    [
      _c("user-profile-authority", { attrs: { "page-p-o-v": "client" } }),
      _c("hr", { staticClass: "Divider" }),
      _c("user-profile-company", { attrs: { "page-p-o-v": "client" } }),
      _c("hr", { staticClass: "Divider" }),
      _c("user-profile-insurance", { attrs: { "page-p-o-v": "client" } }),
      _c("hr", { staticClass: "Divider" }),
      _c("client-profile-account"),
      _c("hr", { staticClass: "Divider" }),
      _c("client-profile-underwriting"),
      _c("hr", { staticClass: "Divider" }),
      _c("client-profile-fleet"),
      _c("hr", { staticClass: "Divider" }),
      _c("client-profile-payment-plan")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }