<template>
  <div class="ClientProfileAccount column">
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Account</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
        data-cy="client-profile-account-edit-btn"
      >
        Edit
      </button>
    </div>

    <!-- BASE -->
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!editing"
        class="row row--wrap"
        key="base"
      >
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">RATE</label>
          <label
            class="fs-14 capitalize"
            id="Testing__ClientFactorRate"
          >
            {{ Number(client.factor_rate_percentage).toFixed(2) }}
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">ACCOUNT MANAGER</label>
          <label class="fs-14 capitalize">
            {{ client.account_manager.first_name }} {{ client.account_manager.last_name }}
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">SALES REP</label>
          <label
            v-if="client.sales_rep"
            class="fs-14 capitalize"
          >
            {{ client.sales_rep.first_name }} {{ client.sales_rep.last_name }}
          </label>
          <label
            v-else
            class="fs-14 capitalize"
          >
            N/A
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">VERIFICATIONS</label>
          <label
            class="fs-14 capitalize"
            data-cy="client-profile-account-verification-text"
          >
            {{ client.requires_verification }}
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">PREFERRED LANGUAGE</label>
          <label class="fs-14 capitalize">{{ client.languages[0] }}</label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">SALES ATTRIBUTION</label>
          <label class="fs-14 capitalize">{{ client.lead_attribution }}</label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">DATE REGISTERED</label>
          <time
            class="fs-14 capitalize"
            :datetime="client.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ client.created_at | datetime('YYYY-MM-DD') }}
          </time>
        </div>
      </div>


      <!-- EDIT -->
      <div
        v-else
        class="column"
        data-cy="client-profile-account-edit-view"
        key="edit"
      >
        <div class="row row--wrap">
          <base-input
            v-model.trim="duplicateUser.factorRate.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'factor-rate'"
            :instructions="instructions.FACTOR_RATE_PERCENTAGE_INSTRUCTION"
            :valid="duplicateUser.factorRate.valid"
          >
            Rate
          </base-input>
          <div class="ClientProfileAccount__select column mb-5 mr-6">
            <label
              class="fc-light fs-12 mb-7"
              for="account-manager"
            >
              Account Manager
            </label>
            <v-select
              v-model="duplicateUser.accountManager.value"
              @input="duplicateUser.accountManager.value = $event"
              class="ClientProfileAccount__select"
              id="account-manager"
              :clearable="false"
              :get-option-label="employee => `${employee.first_name} ${employee.last_name}`"
              :options="accountManagers"
            />
          </div>
          <div class="ClientProfileAccount__select column mb-5 mr-6">
            <label
              class="fc-light fs-12 mb-7"
              for="sales-rep"
            >
              Sales Rep
            </label>
            <v-select
              v-model="duplicateUser.salesRep.value"
              @input="duplicateUser.salesRep.value = $event"
              class="ClientProfileAccount__select"
              id="sales-rep"
              :get-option-label="employee => `${employee.first_name} ${employee.last_name}`"
              :options="salespersons"
            />
          </div>
          <div class="ClientProfileAccount__select column mb-5 mr-6">
            <label
              class="fc-light fs-12 mb-7"
              for="preferred-language"
            >
              Preferred Language
            </label>
            <v-select
              v-model="duplicateUser.preferredLanguage.value"
              @input="duplicateUser.preferredLanguage.value = $event"
              class="ClientProfileAccount__select"
              :clearable="false"
              id="preferred-language"
              :options="languageOptions"
            />
          </div>
          <div class="ClientProfileAccount__select column mb-5 mr-6">
            <label
              class="fc-light fs-12 mb-7"
              for="sales-attribution"
            >
              Sales Attribution
            </label>
            <v-select
              v-model="duplicateUser.leadAttribution.value"
              @input="duplicateUser.leadAttribution.value = $event"
              class="ClientProfileAccount__select"
              :clearable="false"
              id="sales-attribution"
              :options="leadOptions"
            />
          </div>
          <base-checkbox
            v-model="duplicateUser.verifications.value"
            class="mr-6 mb-5"
            data-cy="client-profile-account-verification-input"
            :label-for="'verifications'"
            :starting-value="duplicateUser.verifications.value"
          >
            Verifications
          </base-checkbox>
        </div>

        <div class="row mt-6">
          <base-button
            @click="saveUserDetails"
            @keydown.enter="saveUserDetails"
            class="bg-blue fc-white mr-6"
            data-cy="client-profile-account-save-btn"
          >
            Save
          </base-button>
          <base-button
            @click="cancel"
            @keydown.enter="cancel"
            class="bg-light fc-white"
            data-cy="client-profile-account-cancel-btn"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  Client,
  Employee
} from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseCheckbox from '../../../components/base-checkbox.vue'
import BaseInput from '../../../components/base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../../utils/validation-mixin'

export default {
  name: 'ClientProfileAccount',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseInput,
  },

  mixins: [ValidationMixin],

  async created () {
    // Get employees to change acocunt manager
    try {
      this.accountManagers = (await Employee.getEmployees({
        role: 'Account Manager',
        status: 'active',
      })).data.rows
      this.salespersons = (await Employee.getEmployees({
        role: 'Salesperson',
        status: 'active',
      })).data.rows
    }
    catch (error) {
      this.captureSentryEvent(
        'Client Account "Created Hook: Get Employees"',
        {
          config: error.config,
          data: this.$data,
          details: error,
          props: this.$props,
          response: error.response,
        }
      )
      this.CError(error)
      this.requestFailure({ message: 'There was an issue retrieving employees' })
    }
  },

  data () {
    return {
      accountManagers: null,
      corpTypeOptions: [
        'llc',
        'sole proprietor',
        'corp',
      ],
      editing: false,
      duplicateUser: null,
      languageOptions: ['english', 'spanish', 'punjabi'],
      leadOptions: ['referral', 'web', 'email', 'cold calling'],
      salespersons: null,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.duplicateUser = null
    },

    nowEditing () {
      this.duplicateUser = {
        accountManager: {
          value: this.client.account_manager,
        },
        factorRate: {
          valid: true,
          value: Number(this.client.factor_rate_percentage).toFixed(2),
        },
        leadAttribution: {
          value: this.client.lead_attribution,
        },
        preferredLanguage: {
          value: this.client.languages[0],
        },
        salesRep: {
          value: this.client.sales_rep,
        },
        verifications: {
          value: this.client.requires_verification,
        },
      }
      this.editing = true
    },

    prepareLanguages () {
      const languages = [...this.client.languages]
      const preferred = this.duplicateUser.preferredLanguage.value
      if (this.client.languages.includes(preferred)) {
        // Remove it if it already exists in the languages array
        const newLanguages = this.client.languages.filter(language => language !== preferred)
        // Re-add it to the front of the lagnuages array
        return Array.of(preferred).concat(newLanguages)
      }
      return Array.of(preferred).concat(languages)
    },

    async saveUserDetails () {
      if (!this.validation()) return

      this.progressStart()

      try {
        const updatedClient = (await Client.update({
          account_manager_id: this.duplicateUser.accountManager.value.id,
          factor_rate_percentage: this.duplicateUser.factorRate.value,
          id: this.client.id,
          languages: this.prepareLanguages(),
          lead_attribution: this.duplicateUser.leadAttribution.value,
          requires_verification: this.duplicateUser.verifications.value,
          sales_rep_id: this.duplicateUser.salesRep.value
            ? this.duplicateUser.salesRep.value.id
            : null,
        })).data
        this.$store.commit('UPDATE_STORED_CLIENT', updatedClient)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Account "Client Update"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the client' })
      }

      this.editing = false
      this.progressFinish()
    },

    validation () {
      let valid = true

      // factor rate
      if (
        !this.duplicateUser.factorRate.value
        || !this.isValidFactorRate(this.duplicateUser.factorRate.value)
      ) {
        this.$set(this.duplicateUser.factorRate, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.factorRate, 'valid', true)
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
.ClientProfileAccount

  .BaseInput,
  .v-select
    width: rem(215px)

  .column--width-auto
    margin-bottom: rem(5px)
    margin-right: rem(37px)

    &:last-child
      margin-right: 0

  &__select
    width: auto
</style>