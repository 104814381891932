<template>
  <div class="ClientProfileFleet column">
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Fleet</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
      >
        Edit
      </button>
    </div>

    <!-- BASE -->
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!editing"
        class="row row--wrap"
        key="base"
      >
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">COLD</label>
          <label class="fs-14 capitalize">{{ client.refrigerated_trucks_amount }}</label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">DRYVAN</label>
          <label class="fs-14 capitalize">{{ client.dryvan_trucks_amount }}</label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">FLATBED</label>
          <label class="fs-14 capitalize">{{ client.flatbed_trucks_amount }}</label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">OTHER</label>
          <label class="fs-14 capitalize">{{ client.other_trucks_amount }}</label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">STEPDECK</label>
          <label class="fs-14 capitalize">{{ client.stepdeck_trucks_amount }}</label>
        </div>
      </div>


      <!-- EDIT -->
      <div
        v-else
        class="column"
        key="edit"
      >
        <div class="row row--wrap">
          <base-input
            v-model.trim="duplicateUser.cold.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'cold-trucks'"
            :instructions="instructions.COLD_TRUCKS_INSTRUCTION"
            :valid="duplicateUser.cold.valid"
          >
            Cold Trucks
          </base-input>
          <base-input
            v-model.trim="duplicateUser.dryvan.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'dryvan-trucks'"
            :instructions="instructions.DRYVAN_TRUCKS_INSTRUCTION"
            :valid="duplicateUser.dryvan.valid"
          >
            Dryvan Trucks
          </base-input>
          <base-input
            v-model.trim="duplicateUser.flatbed.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'flatbed-trucks'"
            :instructions="instructions.FLATBED_TRUCKS_INSTRUCTION"
            :valid="duplicateUser.flatbed.valid"
          >
            Flatbed Trucks
          </base-input>
          <base-input
            v-model.trim="duplicateUser.other.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'other-trucks'"
            :instructions="instructions.OTHER_TRUCKS_INSTRUCTION"
            :valid="duplicateUser.other.valid"
          >
            Other Trucks
          </base-input>
          <base-input
            v-model.trim="duplicateUser.stepdeck.value"
            class="mr-6 mb-5"
            :label="true"
            :label-for="'stepdeck-trucks'"
            :instructions="instructions.STEPDECK_TRUCKS_INSTRUCTION"
            :valid="duplicateUser.stepdeck.valid"
          >
            Stepdeck trucks
          </base-input>
        </div>

        <div class="row mt-6">
          <base-button
            @click="saveUserDetails"
            @keydown.enter="saveUserDetails"
            class="bg-blue fc-white mr-6"
          >
            Save
          </base-button>
          <base-button
            @click="cancel"
            @keydown.enter="cancel"
            class="bg-light fc-white"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import { Client } from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseInput from '../../../components/base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../../utils/validation-mixin'

export default {
  name: 'ClientProfileFleet',

  components: {
    BaseButton,
    BaseInput,
  },

  mixins: [ValidationMixin],

  data () {
    return {
      editing: false,
      duplicateUser: null,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.duplicateUser = null
    },

    nowEditing () {
      this.duplicateUser = {
        cold: {
          valid: true,
          value: this.client.refrigerated_trucks_amount.toString(),
        },
        dryvan: {
          valid: true,
          value: this.client.dryvan_trucks_amount.toString(),
        },
        flatbed: {
          valid: true,
          value: this.client.flatbed_trucks_amount.toString(),
        },
        other: {
          valid: true,
          value: this.client.other_trucks_amount.toString(),
        },
        stepdeck: {
          valid: true,
          value: this.client.stepdeck_trucks_amount.toString(),
        },
      }
      this.editing = true
    },

    async saveUserDetails () {
      if (!this.validation()) return

      this.progressStart()

      try {
        const updatedClient = (await Client.update({
          dryvan_trucks_amount: this.duplicateUser.dryvan.value,
          flatbed_trucks_amount: this.duplicateUser.flatbed.value,
          refrigerated_trucks_amount: this.duplicateUser.cold.value,
          other_trucks_amount: this.duplicateUser.other.value,
          stepdeck_trucks_amount: this.duplicateUser.stepdeck.value,
          id: this.client.id,
        })).data
        this.$store.commit('UPDATE_STORED_CLIENT', updatedClient)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Fleet "Update Client"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the client' })
      }

      this.editing = false
      this.progressFinish()
    },

    validation () {
      let valid = true
      // client: cold, dryvan, flatbed, other, stepdeck

      // cold
      if (
        !this.duplicateUser.cold.value
        || !this.isValidTruckNumber(this.duplicateUser.cold.value)
      ) {
        this.$set(this.duplicateUser.cold, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.cold, 'valid', true)
      }

      // dryvan
      if (
        !this.duplicateUser.dryvan.value
        || !this.isValidTruckNumber(this.duplicateUser.dryvan.value)
      ) {
        this.$set(this.duplicateUser.dryvan, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.dryvan, 'valid', true)
      }

      // flatbed
      if (
        !this.duplicateUser.flatbed.value
        || !this.isValidTruckNumber(this.duplicateUser.flatbed.value)
      ) {
        this.$set(this.duplicateUser.flatbed, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.flatbed, 'valid', true)
      }

      // other
      if (
        !this.duplicateUser.other.value
        || !this.isValidTruckNumber(this.duplicateUser.other.value)
      ) {
        this.$set(this.duplicateUser.other, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.other, 'valid', true)
      }

      // stepdeck
      if (
        !this.duplicateUser.stepdeck.value
        || !this.isValidTruckNumber(this.duplicateUser.stepdeck.value)
      ) {
        this.$set(this.duplicateUser.stepdeck, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.stepdeck, 'valid', true)
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
.ClientProfileFleet

  .BaseInput
    width: rem(150px)

  .column--width-auto
    margin-right: rem(37px)

    &:last-child
      margin-right: 0
</style>