var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientProfilePaymentPlan column" },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [
          _vm._v("Payment Plan")
        ]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c(
                "div",
                { key: "base" },
                [
                  _c("div", { staticClass: "row row--wrap" }, [
                    _c("div", { staticClass: "column column--width-auto" }, [
                      _c("label", { staticClass: "fs-14 capitalize" }, [
                        _vm._v(_vm._s(_vm.client.payment_plan_details))
                      ])
                    ])
                  ]),
                  _vm.paymentPlanHistory.length
                    ? _c(
                        "div",
                        {
                          staticClass: "column column--width-auto",
                          on: { click: _vm.toggleHistory }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "fs-14 capitalize mt-12 fc-light clickable"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.showPaymentPlanHistory
                                      ? "Hide History"
                                      : "View History"
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.showPaymentPlanHistory
                    ? _c("invoice-updates", {
                        attrs: {
                          "default-entries": 3,
                          label: "Payment Plan History",
                          updates: _vm.paymentPlanHistory
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                { key: "edit", staticClass: "column" },
                [
                  _c("base-textarea", {
                    staticClass: "ClientProfilePaymentPlan__textarea",
                    attrs: {
                      label: true,
                      "label-for": "payment-plan",
                      value: _vm.client.payment_plan_details
                    },
                    model: {
                      value: _vm.duplicateUser.paymentPlan.value,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.duplicateUser.paymentPlan,
                          "value",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "duplicateUser.paymentPlan.value"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "row row--align-center mt-10" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white mr-6",
                          on: {
                            click: _vm.saveUserDetails,
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.saveUserDetails($event)
                            }
                          }
                        },
                        [_vm._v("\n          Save\n        ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white",
                          on: {
                            click: _vm.cancel,
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.cancel($event)
                            }
                          }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }