var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientProfileUndewriting column" },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [
          _vm._v("Underwriting")
        ]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c(
                "div",
                { key: "notEditing", staticClass: "column" },
                [
                  _c("div", { key: "base", staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "row row--wrap row--width-auto" },
                      [
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("OFAC VERIFIED")]
                            ),
                            _c("label", { staticClass: "fs-14 capitalize" }, [
                              _vm._v(_vm._s(_vm.client.ofac_verified))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("TAX GUARD ALERTS")]
                            ),
                            _c("label", { staticClass: "fs-14 capitalize" }, [
                              _vm._v(_vm._s(_vm.client.tax_guard_alerts))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("CARRIER 411 ALERTS")]
                            ),
                            _c("label", { staticClass: "fs-14 capitalize" }, [
                              _vm._v(_vm._s(_vm.client.carrier_411_alerts))
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _c("div", { key: "update", staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "row row--width-auto" },
                      [
                        _c("invoice-updates", {
                          staticClass: "mt-30",
                          attrs: {
                            "default-entries": 2,
                            label: "Updates",
                            updates: _vm.client.client_underwriting_updates
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "transition",
                    {
                      attrs: {
                        mode: "out-in",
                        name: "Transition__opacity-fade"
                      }
                    },
                    [
                      !_vm.showUpdate
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mt-13",
                              on: { click: _vm.showAddUpdate }
                            },
                            [_vm._v("\n          Add Update\n        ")]
                          )
                        : _c(
                            "div",
                            {
                              key: "updateUpdate",
                              staticClass: "row row--align-center"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-add-circle fa-rotate-45 fa-20 fa-3 mr-9 clickable",
                                attrs: { tabindex: "0" },
                                on: {
                                  click: _vm.cancelUpdate,
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.cancelUpdate($event)
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "row row--align-center row--wrap"
                                },
                                [
                                  _c("label", { staticClass: "fs-14 mr-9" }, [
                                    _vm._v("Add underwriting update")
                                  ]),
                                  _c("v-select", {
                                    attrs: {
                                      "aria-label":
                                        "Updates Subect Select Input",
                                      clearable: false,
                                      options: _vm.subjectOptions,
                                      placeholder: "Subject"
                                    },
                                    model: {
                                      value: _vm.subject,
                                      callback: function($$v) {
                                        _vm.subject = $$v
                                      },
                                      expression: "subject"
                                    }
                                  }),
                                  _c("base-input", {
                                    attrs: {
                                      placeholder: "Underwriting update..."
                                    },
                                    model: {
                                      value: _vm.update,
                                      callback: function($$v) {
                                        _vm.update =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "update"
                                    }
                                  }),
                                  _c(
                                    "base-button",
                                    {
                                      staticClass: "bg-blue fc-white ml-6",
                                      attrs: {
                                        disabled:
                                          !_vm.update.length ||
                                          _vm.buttonDisabled
                                      },
                                      on: { click: _vm.addUnderwritingUpdate }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Save\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            : _c("div", { key: "editing", staticClass: "column" }, [
                _vm.editing
                  ? _c(
                      "div",
                      { key: "edit" },
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              mode: "out-in",
                              name: "Transition__opacity-fade"
                            }
                          },
                          [
                            !_vm.showUpdate
                              ? _c(
                                  "div",
                                  {
                                    key: "checkboxUpdates",
                                    staticClass: "column"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row row--wrap" },
                                      [
                                        _c(
                                          "base-checkbox",
                                          {
                                            staticClass: "mr-16 mb-5",
                                            attrs: {
                                              "label-for": "o-f-a-c-verified",
                                              "starting-value":
                                                _vm.duplicateUser.ofacVerified
                                                  .value
                                            },
                                            model: {
                                              value:
                                                _vm.duplicateUser.ofacVerified
                                                  .value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.duplicateUser
                                                    .ofacVerified,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "duplicateUser.ofacVerified.value"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                OFAC Verified\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "base-checkbox",
                                          {
                                            staticClass: "mr-16 mb-5",
                                            attrs: {
                                              "label-for": "tax-guard-alerts",
                                              "starting-value":
                                                _vm.duplicateUser.taxGuardAlerts
                                                  .value
                                            },
                                            model: {
                                              value:
                                                _vm.duplicateUser.taxGuardAlerts
                                                  .value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.duplicateUser
                                                    .taxGuardAlerts,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "duplicateUser.taxGuardAlerts.value"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Tax Guard Alerts\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "base-checkbox",
                                          {
                                            staticClass: "mr-16 mb-5",
                                            attrs: {
                                              "label-for": "carrier-411-alerts",
                                              "starting-value":
                                                _vm.duplicateUser
                                                  .carrier411Alerts.value
                                            },
                                            model: {
                                              value:
                                                _vm.duplicateUser
                                                  .carrier411Alerts.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.duplicateUser
                                                    .carrier411Alerts,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "duplicateUser.carrier411Alerts.value"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Carrier 411 Alerts\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c(
                                          "base-button",
                                          {
                                            staticClass:
                                              "bg-blue fc-white mr-6",
                                            on: {
                                              click: _vm.saveClientDetails,
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.saveClientDetails(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Save\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "base-button",
                                          {
                                            staticClass: "bg-light fc-white",
                                            on: {
                                              click: _vm.cancelEdit,
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.cancelEdit($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Cancel\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }